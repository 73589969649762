import { cn } from '@ahm/common-helpers';
import type { HTMLAttributes, ReactNode } from 'react';
import MainFooter from './main-footer';
import MainHeader from './main-header';
import classes from './main-layout.module.css';
import MainNavbar from './main-navbar';

export type MainLayoutProps = HTMLAttributes<HTMLElement> & {
  children: ReactNode;
  withNavbar?: boolean;
};

export default function MainLayout({
  children,
  className,
  withNavbar,
  ...props
}: MainLayoutProps) {
  return (
    <>
      <style>
        {`
    :root {
      --main-layout-header-height: 7.125;
    }
    @media (min-width: 768px) {
      :root {
        --main-layout-header-height: 7.125;
      }
    }
  `}
      </style>
      {withNavbar ? (
        <style>{`
          :root {
      --main-layout-navbar-width: 18.75rem;
    }
      `}</style>
      ) : null}
      <MainHeader />
      <main className={cn('bg-accent', className, classes.main)} {...props}>
        {withNavbar ? <MainNavbar /> : null}
        {children}
        <MainFooter />
      </main>
    </>
  );
}
